// LabelPage.js
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import axios from 'axios';


function AddLogins() {
  // URL dan parametrni olish
  const { param } = useParams();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  async function addLoginUser(login, password) {
      document.getElementById("content").style.height = "120px";
      try {
        if (login.length<5 || password.length<5){
          return "❌ Login yoki parol xato";
        } else {
          const loading = document.getElementById("loading");
          loading.classList.remove("hidden")

          await axios.post(`https://api.projectsplatform.uz/kundalikcom/register_logins/${param}`,
            {
              login,
              password
            }
          );

          await sleep(4500);
          loading.classList.add("hidden");
          await sleep(500);
          return "✅ Bitta hisob aktiv qilindi";
        }
      } catch {
        return "❌ Internetga ulanmagansiz";
      }
  }

  const ruyxatdan_utish =  async () => {
    const username_input = document.getElementById("username-field");
    const password_input = document.getElementById("password-field");
    var username = username_input.value;
    var password = password_input.value;
    
    document.getElementById("content").style.minHeight = "120px";
    const res = await addLoginUser(username, password);
    alert(res);
  }

  const containerStyle = {
    maxWidth: '95%',
    width: '600px'
  };
  return (
    <center>
      <div className="container" style={containerStyle}>
      <link rel="stylesheet" href="https://static.emaktab.uz/styles/main/common.css?v=38675ba2" media="all" type="text/css"/>
      <link rel="stylesheet" href="https://static.emaktab.uz/blocks/blocks.css?v=c0cbef8b" media="all" type="text/css" />
      <div className="row">
        <div className="col-md-24 col-xs-24">
          <div className="article-area">
            <form className="login" method="POST">
              <input id="exceededAttempts" name="exceededAttempts" type="hidden" value="True" />
              <input id="ReturnUrl" name="ReturnUrl" type="hidden" value="" />
              <input id="FingerprintId" name="FingerprintId" type="hidden" value="" />

              <div className="login__header login__header_uz">
                <div className="row row_centered">
                  <div className="login__header-title col-md-12 col-xs-12">
                    <div className="login__header__text">
                      <h1 className="h1 h1_white">eMaktab hisobingizni faollashtirish</h1>
                    </div>
                  </div>
                  <div className="login__header-register-wrapper col-md-12 col-xs-12">
                  </div>
                </div>
              </div>

              <div className="login__body">
                <div className="login__body__hint login__body__hint_error-message login__body__hint_hidden">
                  <div className="message"></div>
                </div>

                <div className="login__error_message_mobile"></div>

                <div className="login__content login__content_mixed">
                  <div className="row row_centered login__form_shown">
                    <div className="login-body__field-wrapper col-md-12 col-xs-12">
                      <label className="label label_input-label label_bold">
                        <strong>Login</strong>
                        <input
                          id="username-field"
                          name="login"
                          className="input input__field input_full-width input_with-label-above input_line-height-28px login__body__input_login"
                          type="text"
                          autoComplete="on"
                          data-test-id="login-field"
                        />
                      </label>
                    </div>
                    <div className="login__body-hint-wrapper col-md-12 col-xs-12">
                      <div className="login__body__hint login__body__hint_login login__body__hint_hidden">
                        <div className="yellow-hint yellow-hint_right">
                          <div className="yellow-hint__wrapper">Loginingizni kiriting.</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row row_centered login__form_shown">
                    <div className="login-body__field-wrapper col-md-12 col-xs-12">
                      <label className="label label_input-label label_bold">
                        <strong>Parol</strong>
                        <div
                          id="pass-visibility-button"
                          className={passwordVisible ? 'hide-pass-button' : 'show-pass-button'}
                          onClick={togglePasswordVisibility}
                        ></div>
                        <input
                          name="password"
                          id="password-field"
                          className="input input__field input_full-width input_with-label-above input_line-height-28px login__body__input_password"
                          type={passwordVisible ? 'text' : 'password'}
                          autoComplete="on"
                          data-test-id="password-field"
                        />
                      </label>
                    </div>
                    <div className="login__body-hint-wrapper col-md-12 col-xs-12">
                      <div className="login__body__hint login__body__hint_password login__body__hint_hidden">
                        <div className="yellow-hint yellow-hint_right">
                          <div className="yellow-hint__wrapper">Parolni kiriting.</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="login__body__captcha login__body__captcha_hidden login__form_shown">
                    <div className="row row_centered">
                      <div className="login__captcha-wrapper col-ms-12 col-xs-12">
                        <div className="captcha" name="captcha" id="id-cff6277971b14768bb1ec7f3fee7b6c7">
                          <div className="row">
                            <div className="col-ms-24 col-xs-24">
                              <label className="label label_bold label_input-label label_display-block label_font-size-14 label_">
                                Rasmdagi belgilarni kiriting
                              </label>
                              <input
                                className="input input__field input_display-inline-block input_width-180 input_captcha-validation input_bottom-20"
                                id="id-ce89f9b676794120935bad18afd01ee2"
                                title="Rasmdagi belgilarni kiriting"
                                name="Captcha.Input"
                                placeholder=""
                              />
                              <img alt="captcha image" className="captcha__image" />
                              <button type="button" className="captcha__button"></button>
                              <input name="Captcha.Id" className="captcha__value" type="hidden" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="login__captcha-error-wrapper col-md-12 col-xs-12">
                        <div className="login__body__captcha__error">
                          <div className="yellow-hint yellow-hint_to-left-20 yellow-hint_right yellow-hint_captcha-hint yellow-hint_hidden">
                            <div className="yellow-hint__wrapper"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row row_centered">
                    <div className="login-body__field-wrapper col-md-6 col-xs-6 login__form_shown">
                      <p
                        onClick={ruyxatdan_utish}
                        style={{paddingTop: "10px", userSelect: "none"}}
                        className="login__submit button button_light-green button_top-10 button_full-width"
                        data-test-id="login-button">Tizimga kiring</p>
                    </div>

                    <div className="login-body__field-wrapper login-body__field-wrapper_show-form-btn login__form_shown">
                      <button type="button" className="button button_show-form-btn">"Kundalik" orqali kiring</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </center>
  );
}

export default AddLogins;
