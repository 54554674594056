import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

function Register() {
    const [fullName, setFullName] = useState('');
    const [sex, setSex] = useState(true); // true for male, false for female
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirm, setPasswordConfirm] = useState('');
    const [authToken, setAuthToken] = useState('');
    const [redirectToTokenPage, setRedirectToTokenPage] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        if (token) {
            setAuthToken(token);
        } else {
            setRedirectToTokenPage(true);
        }
    }, [location]);

    const handleRegister = async (e) => {
        e.preventDefault();
        const loading = document.getElementById("loading");
        loading.classList.remove("hidden")
        
        if(password !== password_confirm){
            loading.classList.remove("hidden")
            alert("Parollar mos emas");
            loading.classList.add("hidden")
            return
        }
        try {
            const response = await axios.post('https://api.projectsplatform.uz/accounts/create-user', {
                token: authToken,
                full_name: fullName,
                sex: sex,
                email: email,
                username: username,
                password: password
            });
            window.location.href = '/login';
            // Muvaffaqiyatli xabarni ko'rsatish yoki boshqa harakatlar
        } catch (error) {
            alert('‼️ Ro\'yxatdan o\'tishda xatolik yuz berdi. Iltimos, qayta urinib ko\'ring.');
            window.location.reload();
            // Xatolik haqida foydalanuvchiga ko'rsatish
        }
        loading.classList.add("hidden")
    };

    if (redirectToTokenPage) {
        navigate('/enter-token'); // Tokenni kiritish sahifasiga yo'naltirish
        return null; // Komponentni render qilmaslik
    }

    return (
        <div id="login_page">
            <form onSubmit={handleRegister}>
                <div>
                    <label>To'liq ismingiz</label>
                    <input
                        type="text"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                    />
                </div>
                <div>
                    <select value={sex} onChange={(e) => setSex(e.target.value)}>
                        <option value={true}>Erkak</option>
                        <option value={false}>Ayol</option>
                    </select>
                </div>
                <div>
                    <label>Elektron pochta</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div>
                    <label>Username</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div>
                    <label>Parol</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div>
                    <label>Parolni qayta kiriting</label>
                    <input
                        type="password"
                        value={password_confirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                    />
                </div>
                <button type="submit">Ro'yxatdan o'tish</button>
            </form>
        </div>
    );
}

export default Register;
