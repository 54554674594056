import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div id="home_page">
      <div>
        <h1 class="home_card_name">ProjektsPlatform</h1>
        <p class="home_card_text">Biz bilan hammasi oson!</p>
      </div>
      <div class="card">
        <h1>KundalikCOM avto login </h1>
        <p>
          Maktablarda kundalik kom tizimida faol bo'lmagan maktablar <strong>eMaktab.uz</strong> tizimida faollikni 100% ga ko'tarish uchun xizmat qiladi!
        </p>
        <p>Qishloq sharoitida eMaktab.uz tizimida faol bo'lish uchun ba'zi oilalarda imkoniyat yetarli bo'lmasligi mumkin.</p>
        <p>Biz bilan buning yechimi bor</p>
        <nav>
          <li>O'quvchilarni avto login qilish</li>
          <li>Ota onalarni hisobiga avto login qilish</li>
          <li>Maktab reytingini tez va qulay oshirsh</li>
        </nav>
        <Link to="/about">Batafsil</Link>
      </div>
    </div>
  );
}

export default Home;
