import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [step, setStep] = useState(1);

  const handleLogin = async (e) => {
    e.preventDefault();
    const loading = document.getElementById("loading");
    loading.classList.remove("hidden")
    try {
      const response = await axios.post('https://api.projectsplatform.uz/accounts/login', {
        username,
        password
      });

      if (response.data) {
        setStep(2);
      }
    } catch (error) {
      window.location.reload();
      console.error('Loginda xatolik');
    }
    loading.classList.add("hidden")
  };

  const handleCheckCode = async (e) => {
    e.preventDefault();
    const loading = document.getElementById("loading");
    loading.classList.remove("hidden")
    try {
      const response = await axios.post('https://api.projectsplatform.uz/accounts/check-login-code', {
        username,
        password,
        code
      });

      if (response.data) {
        // Kod to'g'ri bo'lsa, tokenni cookie-ga saqlash
        document.cookie = `authToken=${response.data.token}; path=/`;
        window.location.href = '/dashboard';
      }
    } catch (error) {
      window.location.reload();
      console.error('Kod tasdiqlashda xatolik');
      alert('Kod xato.');
    }
    loading.classList.add("hidden")
  };

  return (
    <div id="login_page">
      {step === 1 ? (
        <form onSubmit={handleLogin}>
          <h2>KIRISH</h2>
          <div>
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <label>Parol</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Link to="/reset-password" class="parol_esdan_chiqdi">Parolingiz esingizdan chiqdimi?</Link>
          <button type="submit">Login</button>
          <a href="tg://resolve?domain=projectsplatformbot"  class="ruyxatdan_utish">Ro'yxatdan o'tish</a>
        </form>
      ) : (
        <form onSubmit={handleCheckCode} >
          <h2>Kodni Tasdiqlash</h2>
          <div>
            <label>Kod:</label>
            <input
              type="number"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          <button type="submit">Tasdiqlash</button>
        </form>
      )}
    </div>
  );
}

export default Login;
