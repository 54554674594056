import Login from './account/login';
import Register from './account/register';
import Dashboard from './account/dashborad';
import ResetPassword from './account/resetpassword.js';
import Home from './home';
import About from './about';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import main_image from './templates/main.png';
import AddLogins from './add_logins.js';


function App() {
  const menu_show_button = (event) => {
    const button = event.currentTarget;
    var div = document.getElementById('asosiy_navbar');
    if (button.innerText === "≡") {
      button.innerText = "×";
      div.style.height = '400px';
    } else {
      button.innerText = "≡";
      div.style.height = '60px';
    }
  };
  const closeMessage = () => {
    var div = document.getElementById('message');
    div.classList.add("hidden")
  };
  const start_func = () => {
    const value = `; ${document.cookie}`;
    const parts = value.split("; authToken=");
    if (parts.length === 2){
      const token = parts.pop().split(';').shift();
      return true
    }else{
      return false
    }

  }
  const buy_func = async () => {
    const value = `; ${document.cookie}`;
    const parts = value.split("; authToken=");
    const input = document.getElementById("months_count");
    if (parts.length === 2){
      const token = parts.pop().split(';').shift();
      let months_count;
      try {
        try{
          months_count = parseInt("0"+input.innerText);
          if(!months_count){
            alert("❌ Nimadur xato ketdi")
            closeMessage()
            return
          }

        }catch{
          alert("❌ Nimadur xato ketdi\nInternetga ulanishni tekshirib ko'ring")
          closeMessage()
          return
        }
        const response = await axios.post('https://api.projectsplatform.uz/kundalikcom/buy', {
          token,
          months_count
        });

        if (response.data.how) {
          console.log(response.data)
          alert("✅ To'lov amalga oshirildi")
          closeMessage();
          window.location.reload();
        }else{
          alert(`❌ ${response.data.message}`)
          closeMessage()
          console.clear()
        }
      } catch {
        alert("❌ Nimadur xato ketdi\nInternetga ulanishni tekshirib ko'ring")
        closeMessage()
        console.clear()
      }
    }

  }
  

  return (
    <Router>
      <div id="loading" class="hidden">
        <div id="content">
          <div id="anim"></div>
          <div id="item">
            <img src={main_image} />
          </div>
        </div>
      </div>
    
      <div id="message" class="hidden">
        <div>
          <h1>Sotib olishni istaysizmi?</h1>
          <p>Jami: <strong id="buy_all_price">0</strong> so'm</p>
          <p class="hidden" id="months_count">0</p>
          <span><button onClick={closeMessage}>Yo'q</button>
          <button onClick={buy_func}>Ha</button></span>
        </div>
      </div>

      <div id="asosiy_template">
        <nav id="asosiy_navbar">
          <ul>
            <li>
            <button id="menu_button" onClick={menu_show_button}>≡</button>
              <Link to="/" id="web_site_name"><img src={main_image} />ProjectsPlatform</Link>
            </li>
            <li class="asosiy_navbar_item">
              <Link to="/">BOSH SAHIFA</Link>
            </li>
            <li class="asosiy_navbar_item">
              <Link to="/about">BIZ HAQIMIZDA</Link>
            </li>
            {start_func() ? (
              <li class="asosiy_navbar_item">
                <Link to="/dashboard" id="dash_button">KABINET 🔒</Link>
              </li>
            ) : (
              <li class="asosiy_navbar_item">
                <Link to="/login" id="login_button">KIRISH</Link>
              </li>
              )}
          </ul>
        </nav>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/maktabga_login_parolni_tanitish/:param" element={<AddLogins />} />
        </Routes>
        
      <footer>
        <hr></hr>
        <p>© ProjectsPlatform 2024. Barcha huquqlar himoyalangan.</p>
        <a herf=""></a>
        <a herf=""></a>
        <a herf=""></a>
        <a herf=""></a>
      </footer>
      </div>
    </Router>
  );
}

export default App;
