import React from 'react';
import { Link } from 'react-router-dom';
import image_1 from "./templates/kundalikcom/image-1.png"
import image_2 from "./templates/kundalikcom/image-2.png"
import image_3 from "./templates/kundalikcom/image-3.png"
import image_4 from "./templates/kundalikcom/image-4.png"
function About() {
  return (
    <div id="home_page">
      <div>
        <h1 class="home_card_name">ProjektsPlatform</h1>
        <p class="home_card_text">Biz bilan hammasi oson!</p>
      </div>
      <div>
        <h1>Ushbu platforma raqamli asrda barcha sohalarda yengilliklar va qulayliklar yaratish uchun ishlab chiqildi</h1>
        <p>Ayni paytda bizda <strong>KundalikCOM auto login</strong> xizmatimiz mavjud</p>
        <h1>Bu nima uchun xizmat qiladi?</h1>
        <p>Ushbu xizmatimiz desktop yani Windows 64x operatsion sestemali kompyuterlar uchun mo'ljallangan dastur hisoblanib, maktabning barcha foydalanuvchilarini login parollarini kiritilgan holatda ularga ancha tez fursatda login qilib chiqish oqali maktab reytingini ko'taradi.</p>
        <p>
          Maktablarda kundalik kom tizimida faol bo'lmagan maktablar <strong>eMaktab.uz</strong> tizimida faollikni 100% ga ko'tarish uchun foydalanishlari mumkin!
        </p>
        
        <h1>Reytingni qay tarzda ko'taradi?</h1>
        Reyting to'liqligicha maktabda faoliyat yurutuvchilarning tizimga kunlik kirishlariga asoslanadi. Yani barcha foydalanuvchilar kun mobaynida tizimga eng kamida 1 marta kirishi talab etiladi.
        Afsuski maktablarda ota-onalar, o'quvchilar bunga laqaydlik bilan qaramoqdalar. Hamma o'quvchilarning login ava parollarini olib bittalab kirib chiqish deyarli imkonsiz. Ammo, bizning xizmatmiz orqali buning iloji bor.
        <nav>
          <li>O'quvchilarni avto login qilish</li>
          <li>Ota onalarni hisobiga avto login qilish</li>
        </nav>
      </div>
      <div class="card" style={{overflow: "auto"}} id="about_full_card">
<h1 class="about_text">Bu qanday ishlaydi?</h1>
<h2 class="about_text">Dastur interfeysidan qisqa lavhalar</h2>
<p style={{display: "flex"}}>
<img src={image_1} class="about_images"></img>
<h2 class="about_text"><br></br>Bitta administrator login paroli orqali barcha ma'lumotlarni olish</h2>
</p>
<p style={{display: "flex"}}>
<h2 class="about_text"><br></br>Login paroli kiritilmaganlarni tekshirib ko'rish va oson kiritish</h2>
<img src={image_2} class="about_images"></img>
</p>
<p style={{display: "flex"}}>
<img src={image_3} class="about_images"></img>
<h2 class="about_text"><br></br>Login paroli kiritilmaganlarni tekshirib ko'rish va oson kiritish</h2>
</p>
<p style={{display: "flex"}}>
<h2 class="about_text"><br></br>Login paroli kiritilmaganlarni tekshirib ko'rish va oson kiritish</h2>
<img src={image_4} class="about_images"></img>
</p>
      <a href="tg://resolve?domain=projectsplatformbot" style={{fontSize: "5dvw", margin: "2dvw", color: "cyan", marginTop: "40px"}}  class="ruyxatdan_utish">Hoziroq ro'yxatdan o'tish</a>
      </div>

    </div>
  );
}

export default About;
