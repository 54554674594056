import React from 'react';
import "./dashboard.css"
import axios from 'axios';

import profileimage from "../templates/dashboard/user.png"
import admin_1 from "../templates/admins/admin-1.jpg"
import payment_button from "../templates/dashboard/payment.png"
import admin_2 from "../templates/admins/admin-2.jpg"
import telegram_image from "../templates/telegram.png"
import kundalikcom_icon from "../templates/kundalikcom_icon.png"
import download_image from "../templates/download.png" // Import the download_image


function Dashboard() {
  
  function copyText() {
    const textElement = document.getElementById('text-to-copy');
    const text = textElement.innerText;

    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
        document.execCommand('copy');
        alert('Matn nusxalandi!');
    } catch (err) {
        console.error('Nusxalashda xatolik:', err);
    }
    document.body.removeChild(textarea);
  }
  function formatPhoneNumber(phoneNumber) {
    // Telefon raqamining to'g'ri bo'lishini tekshirish
    const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // faqat raqamlarni olib tashlash
  
    // Agar raqamning uzunligi 12 bo'lmasa, noto'g'ri format
    if (cleaned.length !== 12) {
      return 'Noto\'g\'ri raqam uzunligi';
    }
  
    // Telefon raqamni formatlash
    const countryCode = cleaned.substring(0, 3); // +998
    const areaCode = cleaned.substring(3, 5); // 912
    const firstPart = cleaned.substring(5, 8); // 345
    const secondPart = cleaned.substring(8, 10); // 67
    const thirdPart = cleaned.substring(10); // 8
  
    return `+${countryCode} (${areaCode}) ${firstPart} ${secondPart} ${thirdPart}`;
  }
  const logout = () => {
      document.cookie = 'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      window.location.href="/login"
    
  }
  async function add_yil() {
    const input = document.getElementById("yil_input");
    try{
      input.value = `${parseInt("0"+input.value)+1} yil`;
      const responce = await axios.post("https://api.projectsplatform.uz/kundalikcom/price_months",{
        months_count: parseInt("0"+input.value)*12
      })
      if(responce.data){
        let sum = new Intl.NumberFormat('en-US').format(responce.data);
        document.getElementById("all_yil_price").innerText = `${sum}`
      }
    }catch{
      input.value = "1 yil";
    }
  }
  async function rem_yil() {
    const input = document.getElementById("yil_input");
    try{
      let value = parseInt("0"+input.value);
      if(value !== 0){
        input.value = `${value-1} yil`;
        const responce = await axios.post("https://api.projectsplatform.uz/kundalikcom/price_months",{
          months_count: parseInt("0"+input.value)*12
        })
        if(responce.data){
          let sum = new Intl.NumberFormat('en-US').format(responce.data);
          document.getElementById("all_yil_price").innerText = `${sum}`
        }else{
          document.getElementById("all_yil_price").innerText = "0"
        }
      }
    }catch{
      input.value = `${0} yil`;
    }
  }
  async function add_oy() {
    const input = document.getElementById("oy_input");
    try{
      input.value = `${parseInt("0"+input.value)+1} oy`;
      const responce = await axios.post("https://api.projectsplatform.uz/kundalikcom/price_months",{
        months_count: parseInt("0"+input.value)
      })
      if(responce.data){
        let sum = new Intl.NumberFormat('en-US').format(responce.data);
        document.getElementById("all_oy_price").innerText = `${sum}`
      }
    }catch{
      input.value = "1 oy";
    }
  }
  async function rem_oy() {
    const input = document.getElementById("oy_input");
    try{
      let value = parseInt("0"+input.value);
      if(value !== 0){
        input.value = `${value-1} oy`;
        const responce = await axios.post("https://api.projectsplatform.uz/kundalikcom/price_months",{
          months_count: parseInt("0"+input.value)
        })
        if(responce.data){
          let sum = new Intl.NumberFormat('en-US').format(responce.data);
          document.getElementById("all_oy_price").innerText = `${sum}`
        }else{
          document.getElementById("all_oy_price").innerText = "0"
        }
      }
    }catch{
      input.value = `${0} oy`;
    }
  }
  

  function open_buy_oy(){
    const months_count = document.getElementById("months_count");
    const input = document.getElementById("oy_input");
    try{
      open_buy_panel()
      months_count.innerText = `${parseInt("0"+input.value)}`;
      document.getElementById("buy_all_price").innerText = document.getElementById("all_oy_price").innerText;
    }catch{

    }
  }

  function open_buy_yil(){
    const months_count = document.getElementById("months_count");
    const input = document.getElementById("yil_input");
    try{
      open_buy_panel()
      months_count.innerText = `${parseInt("0"+input.value)*12}`;
      document.getElementById("buy_all_price").innerText = document.getElementById("all_yil_price").innerText;
    }catch{

    }
  }
  function formatDate(isoString) {
    const date = new Date(isoString);

    const year = date.getFullYear();
    const day = date.getDate();
    const month = date.getMonth(); // 0-11 oralig'ida
    const monthNames = [
        'Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'Iyun',
        'Iyul', 'Avgust', 'Sentabr', 'Oktabr', 'Noyabr', 'Dekabr'
    ];
    
    return `${year} - yil ${day} - ${monthNames[month]}`;
}

  const start_func = async () => {
    const value = `; ${document.cookie}`;
    const parts = value.split("; authToken=");
    if (parts.length === 2){
      const token = parts.pop().split(';').shift();
      console.log(token)
      try {
        const responce = await axios.post("https://api.projectsplatform.uz/accounts/about_account", {
          token
        });
        if(responce.data){
          var user = responce.data;
          
          var phone = formatPhoneNumber(user.phone);



          const phone_page = document.getElementById("phone")
          const fullname_page = document.getElementById("fullname")
          const username_page = document.getElementById("username")
          const email_page = document.getElementById("email")
          const balance_page = document.getElementById("balance_value")
          const tg_id_page = document.getElementById("tg_id")
          phone_page.innerText = phone;
          fullname_page.innerText = user.full_name;
          username_page.innerText = user.username;
          tg_id_page.innerText = user.tg_id;
          email_page.innerText = user.email;
          let balance = new Intl.NumberFormat('en-US').format(user.balance);
          balance_page.innerText = `${balance} so'm`;
        } else {
          // window.location.href = "/"
        }
      } catch {
        // window.location.href = "/"
      }
      try {
        const response = await axios.post('https://api.projectsplatform.uz/kundalikcom/about_kundalikpc', {
          token
        });

        if (response.data) {
          // letsenziya malumotlarni kiritish
          console.log(response.data);
          document.getElementById("letsenziya_label").innerText = formatDate(response.data.end_active_date);
          try{
            const response = await axios.post('https://api.projectsplatform.uz/kundalikcom/get_school', {
              token
            });
            if (response.data.how){
              // maktab ma'lumotlarni kiritish
              const maktab_nomi = document.getElementById("maktab_nomi");
              const joylashuv = document.getElementById("joylashuv");
              maktab_nomi.innerText = response.data.school_name;
              joylashuv.innerText = `${response.data.viloyat} viloyati ${response.data.tuman} tumani`
            }else{
              document.getElementById("maktab_div").classList.add("hidden")
              document.getElementById("maktab_label").classList.add("hidden")
            }
          } catch(error) {
            console.log(error);
            document.getElementById("maktab_div").classList.add("hidden")
            document.getElementById("maktab_label").classList.add("hidden")
          }

        }else{
          document.getElementById("letsenziya_div").classList.add("hidden")
          document.getElementById("maktab_div").classList.add("hidden")
          document.getElementById("letsenziya_label").classList.add("hidden")
          document.getElementById("maktab_label").classList.add("hidden")
          console.clear()
        }
      } catch (error) {
        document.getElementById("letsenziya_div").classList.add("hidden")
        document.getElementById("maktab_div").classList.add("hidden")
        document.getElementById("letsenziya_label").classList.add("hidden")
        document.getElementById("maktab_label").classList.add("hidden")
        console.clear()
      }
    }

  }
  start_func();



  function open_buy_panel(message){
    var message_div = document.getElementById("message");
    try{
      message_div.classList.remove("hidden");
    }catch{}

  }











  const menu_show_button = (event) => {
    const button = event.currentTarget;
    const buttons = document.querySelectorAll(".yon_panel_buttons");
    Array.from(buttons).forEach(button => {
      button.classList.remove("active_panel_button");
      button.style.backgroundColor = 'rgb(50,50,50,0.3)';
      button.style.color = 'black';
    });
    button.classList.add("active_panel_button");
    button.style.backgroundColor = 'rgb(50,50,50,0.5)';
    button.style.color = 'cyan';
    const page_hisob = document.getElementById("dash_hisob");
    const page_kundalik = document.getElementById("dash_kundalik_com");
    const page_buy = document.getElementById("dash_buy");
    if(button.innerText === "Hisob"){
      page_hisob.classList.remove("hidden");
      page_kundalik.classList.add("hidden");
      page_buy.classList.add("hidden");
    }else if (button.innerText === "KundalikCOM"){
      page_hisob.classList.add("hidden");
      page_kundalik.classList.remove("hidden");
      page_buy.classList.add("hidden");
    }else{
      page_hisob.classList.add("hidden");
      page_kundalik.classList.add("hidden");
      page_buy.classList.remove("hidden");
    }
  }
  return (
    <div id="dashboard_page">

      <div id="buy_panel" class="hidden">
      </div>
        <div id="dash_yon_panel">
          <button class="yon_panel_buttons" onClick={menu_show_button}>
            <img src={profileimage} />
            Hisob
          </button>
          <button class="yon_panel_buttons active_panel_button" onClick={menu_show_button} style={{backgroundColor: "rgb(50,50,50,0.5)", color: "cyan"}}>
            <img src={kundalikcom_icon} />
            KundalikCOM
          </button>
          <button class="yon_panel_buttons" onClick={menu_show_button}>
            <img src={payment_button} />
            Hisobni to'dirish
          </button>
        </div>
        <div id="dash_content">
          <p id="balance_page">Balansingiz<span id="balance_value">0 so'm</span></p>
          <div class='dash_hisob_pages hidden' id="dash_hisob">
            <div class="card" id="profile_page">

              <img src={profileimage} id="profile_photo" />
              <div>
                <p>To'liq ism</p>
                <h2 id="fullname">Full Name</h2>
                <p>Username</p>
                <h2  id="username">Username</h2>
                <p>@E-Pochta</p>
                <h2 id="email">-</h2>
                <p>Telefon raqam</p>
                <h2 id="phone">+998 (__) ___ __ __</h2>
                <button id="yon_panel_logout" onClick={logout}>
                  <img src='' />
                  Chiqish
                </button>
              </div>
            </div>
          </div>
          <div class='dash_hisob_pages' id="dash_kundalik_com">
            <div id="maktab_mals">
              <div class="card_dash" style={{border: "5px dashed rgb(0,255,0)", overflow: "hidden"}}>
              <center><h3 style={{paddingRight: "20px"}}>🎁 Bonus </h3></center>
                <h1>Ulashing va bepul letsenziyaga ega bo'ling</h1>
                <h2>Ushbu xizmatimizni boshqalarga ulashing va xizmatimizni har bir sotib olgan foydalanuvchi uchun <span style={{color: "rgb(0,255,0)"}}>1 oylik</span> bepul letsenziyani qo'lga kiriting!</h2>
                
                <p><span id="text-to-copy">https://t.me/projectsplatformbot?start=<span id="tg_id"></span></span> <button class="copy-button" onClick={copyText}>Havolani nusxalash</button></p>
                <h1 style={{color: "rgb(0,255,0)"}}>Hoziroq ushbu referal havolangizdan nusxa olib telegram orqali ulashing</h1>
              </div>
              <p id="maktab_label">Maktabingiz</p>
              <div class="card_dash" id="maktab_div">
                <p>Maktab nomi</p>
                <h2 id="maktab_nomi"></h2>
                <p>Joylashuv</p>
                <h2 id="joylashuv"></h2>
              </div>
              <p>Letsenziya</p>
              <div class="card_dash" id="letsenziya_div">
                <p>Letsenziya tugash vaqti</p>
                <h2 id='letsenziya_label'>Letsenziya mavjud emas</h2>
              </div>
              <div style={{overflow: "auto", width: "100%"}}>
                <p>Litsenziya olish</p>
                <div class="card_dash_buy">
                  <p>Oyiga</p>
                  <h2><span>250,000</span> so'm</h2>
                  <p>3 oy yoki undan ko'proq xarid</p>
                  <h2><span>20% chegirma bilan</span><br/>Oyiga <span>200,000</span> so'm</h2>
                  <div class="buy_panel"><button onClick={rem_oy}>-</button><input type="text" id='oy_input'></input><button onClick={add_oy}>+</button></div>
                  <h3>Umumiy: <span id="all_oy_price">1,800,000</span> so'm</h3>
                  <button class="buy_button" onClick={open_buy_oy}>Sotib olish</button>
                </div>
                <div class="card_dash_buy">
                  <p>Yillik</p>
                  <span>12 oy davomida hechqanday cheklovlarsiz</span>
                  <h2><span>1,800,000</span> so'm</h2>
                  <div class="buy_panel"><button onClick={rem_yil}>-</button><input type="text" id='yil_input'></input><button onClick={add_yil}>+</button></div>
                  <h3>Umumiy: <span id='all_yil_price'>1,800,000</span> so'm</h3>
                  <button class="buy_button" onClick={open_buy_yil}>Sotib olish</button>
                </div>
              </div>
              <div class="card_dash" style={{overflow: "auto"}}>
                <h1>KundalikCOM auto login Desktop(Windows 64x tizimi uchun)</h1>
                <a href="/kundalikcom/KundalikCOM_64x.exe" download id="download_button"> <img src={download_image}></img>Yuklab olish</a>
              </div>
            </div>
          </div>
          <div class='dash_hisob_pages hidden' id="dash_buy" style={{float: "left"}}>
            <h1>Hisobingizni to'ldirish uchun adminlarimiz bilan Telegram orqali bog'lanishingiz lozim</h1>
            <div class="admin_card">
              <p>Admin 1</p>
              <h1>Bexruz Boynazarov</h1>
              <div class="links">
                <a href="https://t.me/bexruzdeveloper"><img src={telegram_image}></img>Telegram</a>
              </div>
            </div>
            <div class="admin_card">
              <p>Admin 2</p>
              <h1>Dilmurod Amonov</h1>
              <div class="links">
                <a href="https://t.me/Softwere_engineer006"><img src={telegram_image}></img>Telegram</a>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Dashboard;
